import { Helmet, HelmetProvider } from 'react-helmet-async';
import ContactInfo from '../Images/contact.png';
import { useState, useRef, useEffect } from "react";
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha"
import Hit from '../Hit/Hit'

const Contact = () => {

	const [email, setEmail] = useState("");
	const [name, setName] = useState("");
	const [telephoneNumber, setTelephoneNumber] = useState("");
	const [subject, setSubject] = useState("");
	const [organization, setOrganization] = useState("");
	const [messageBody, setMessageBody] = useState("");
    const [buttonClicked, setButtonClicked] = useState(false);
	const [message, setMessage] = useState("");
	const [alertVariant, setAlertVariant] = useState("danger");
	const captchaRef = useRef(null);

	const hitLogged = useRef(false);
	useEffect(()=>{ 
		if (hitLogged.current) return;
    	hitLogged.current = true;
		Hit(4) 
	},[])

	let handleSubmit = async (e) => {
        e.preventDefault();
        try {
			if (captchaRef.current.getValue() === null || captchaRef.current.getValue() === "") {
				setAlertVariant("danger");
				setMessage("You must complete the recapcha.");
				return
			}
            setButtonClicked(true);
			const formData = new FormData();
			formData.append("email", email);
			formData.append("name", name);
			formData.append("telephoneNumber", telephoneNumber);
			formData.append("organization", organization);
			formData.append("subject", subject);
			formData.append("message", messageBody);
			formData.append("captchaToken", captchaRef.current.getValue());
			captchaRef.current.reset();
			const res = await axios.post(process.env.REACT_APP_BASE_API_URL + "/contact/create", formData); 
			setAlertVariant("primary");
			setMessage(res.data.message);
			setButtonClicked(false);
			setEmail("");
			setName("");
			setTelephoneNumber("");
			setSubject("");
			setOrganization("");
			setMessageBody("");
        } catch (err) {
			setButtonClicked(false);
			setAlertVariant("danger");
			if (err.response) {
				// The client was given an error response (5xx, 4xx)
				if ((err.response.data.message !== undefined) && (err.response.data.message.length !== 0)) {
					setMessage(err.response.data.message)
				} else {
					setMessage("Unknown Error");
				}
			} else {
				setMessage("Unknown Error");
			}
        }
    };

	return (
		<HelmetProvider>
			<Helmet>
				<title>{ process.env.REACT_APP_APP_NAME } - Contact</title>
			</Helmet>
			<div className='d-flex align-items-center justify-content-center paragraph-body' style={{paddingBottom: '80px'}}>
                <div className="col-lg-6 content-wrap">
                    <div className="center">
                        <h2>
                            Contact
                        </h2>
						<img src={ContactInfo} alt='Realistic Development - Contact' style={{ maxWidth: '90vw' }} />
					</div>
					<div style={{ padding: '10px', marginTop:'10px' }} >
						<table style={{ width: '100%'}} className='align-items-center justify-content-center'>
							<tbody>
								<tr>
									<td className="d-flex align-items-center justify-content-center">    
										<h3>Send A Message</h3>
									</td>
								</tr>
								<tr>
									<td className="d-flex align-items-center justify-content-center">    
										{message ? <Alert key={alertVariant} variant={alertVariant}>{message}</Alert> : null}
									</td>
								</tr>
								<tr>
									<td className="d-flex align-items-center justify-content-center">
										<Form onSubmit={handleSubmit}>
											<Form.Group className="mb-3" controlId="formBasicName" style={{ minWidth: '40vw', maxWidth: '90vw' }}>
												<Form.Label>Name*</Form.Label>
												<Form.Control type="text" placeholder="Enter Name"
												value={name}
												name="name" 
												onChange={(e) => setName(e.target.value)} 
												required />
											</Form.Group>
											<Form.Group className="mb-3" controlId="formBasicEmail" style={{ minWidth: '40vw', maxWidth: '90vw' }}>
												<Form.Label>Email Address*</Form.Label>
												<Form.Control type="email" placeholder="Enter Email"
												value={email}
												name="email" 
												onChange={(e) => setEmail(e.target.value)} 
												required />
											</Form.Group>
											<Form.Group className="mb-3" controlId="formBasicTelephoneNumber">
												<Form.Label>Telephone Number</Form.Label>
												<Form.Control type="text" placeholder="Enter Telephone Number"
												value={telephoneNumber}
												name="telephoneNumber" 
												onChange={(e) => setTelephoneNumber(e.target.value)} 
												/>
											</Form.Group>
											<Form.Group className="mb-3" controlId="formBasicOrganization" style={{ minWidth: '40vw', maxWidth: '90vw' }}>
												<Form.Label>Organization</Form.Label>
												<Form.Control type="text" placeholder="Enter Organization"
												value={organization}
												name="organization" 
												onChange={(e) => setOrganization(e.target.value)} 
												/>
											</Form.Group>
											<Form.Group className="mb-3" controlId="formBasicSubject" style={{ minWidth: '40vw', maxWidth: '90vw' }}>
												<Form.Label>Subject*</Form.Label>
												<Form.Control type="text" placeholder="Enter Subject"
												name="subject" 
												value={subject}
												onChange={(e) => setSubject(e.target.value)} 
												required />
											</Form.Group>
											<Form.Group className="mb-3" controlId="formBasicMessage" style={{ minWidth: '40vw', maxWidth: '90vw' }}>
												<Form.Label>Message*</Form.Label>
												<Form.Control as="textarea" placeholder="Enter Message"
												name="messageBody" 
												value={messageBody}
												rows={5}
												onChange={(e) => setMessageBody(e.target.value)} 
												required />
											</Form.Group>
											<Form.Group className="mb-3" controlId="formBasicMessage" style={{ minWidth: '40vw', maxWidth: '90vw' }}>
												Required Fields *
											</Form.Group>	
											<Form.Group className="mb-3" controlId="formBasicReCapcha" style={{ minWidth: '40vw', maxWidth: '90vw' }}>
												<ReCAPTCHA
													sitekey={process.env.REACT_APP_SITE_KEY}
													ref={captchaRef}
												/>	
											</Form.Group>
																		
											<Button variant="secondary" type="submit" hidden={buttonClicked}>
												Send Message
											</Button>
											<Button variant="secondary" disabled hidden={!buttonClicked}>
												<Spinner
													as="span"
													animation="grow"
													size="sm"
													role="status"
													aria-hidden="true"
												/>
												Sending Message...
											</Button>
										</Form>
									</td>
								</tr>
						</tbody>
					</table>
					</div>
                </div>
			</div>
		</HelmetProvider>
	);
}

export default Contact;