import { useRef, useEffect } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from "react-router-dom";
import CantKiss from '../Images/cant_kiss.png';
import WastingMoney from '../Images/wasting_money.png';
import Hit from '../Hit/Hit'

const Articles = () => {

	const hitLogged = useRef(false);
	useEffect(()=>{ 
		if (hitLogged.current) return;
    	hitLogged.current = true;
		Hit(3) 
	},[])

	return (
		<HelmetProvider>
			<Helmet>
				<title>{ process.env.REACT_APP_APP_NAME } - Articles</title>
			</Helmet>
			<div className='d-flex align-items-center justify-content-center paragraph-body'>
                <div className="col-lg-6 content-wrap">
                    <div className="center">
                        <h2>
                            Articles
                        </h2>
						<div style={{paddingTop: '20px'}}>
							<p>
								<Link to="https://www.linkedin.com/pulse/engineers-cant-kiss-dennis-ketner/" target="_blank">
									<img src={CantKiss} alt="Engineer Can't Kiss" style={{ width: '60vh', maxWidth: '90vw' }} />
								</Link>
							</p>
						</div>
						<div style={{paddingTop: '20px', paddingBottom: '80px'}}>
							<p>
								<Link to="https://www.linkedin.com/pulse/you-wasting-money-dennis-ketner" target="_blank">
									<img src={WastingMoney} alt="You Are Wasting Money" style={{ width: '60vh', maxWidth: '90vw' }} />
								</Link>
							</p>
						</div>
                    </div>
                </div>
			</div>
		</HelmetProvider>
	);
}

export default Articles;