import axios from 'axios';

const Hit = (pageId) => {
	try {
        axios.get(process.env.REACT_APP_BASE_API_URL + "/hit/create", { params: { pageId: pageId } }); 
    } catch (err) {
        if (err.response) {
            // The client was given an error response (5xx, 4xx)
            if ((err.response.data.message !== undefined) && (err.response.data.message.length !== 0)) {
                console.log(err.response.data.message)
            } else {
                console.log("Unknown Error");
            }
        } else {
            console.log("Unknown Error");
        }
    }
}
export default Hit;