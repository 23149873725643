import { useRef, useEffect } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Hit from '../Hit/Hit'

const About = () => {

    const hitLogged = useRef(false);
	useEffect(()=>{ 
		if (hitLogged.current) return;
    	hitLogged.current = true;
		Hit(2) 
	},[])

	return (
		<HelmetProvider>
			<Helmet>
				<title>{ process.env.REACT_APP_APP_NAME } - About</title>
			</Helmet>
			<div className='d-flex align-items-center justify-content-center paragraph-body' style={{paddingBottom: '80px'}}>
                <div className="col-lg-6 content-wrap" style={{ maxWidth: "90%", width: '60vh' }}>
                    <div className="center">
                        <h2>
                            About
                        </h2>
                    </div>
                    <p style={{ paddingTop: '30px' }}>
                        <h4>
                            Mission
                        </h4>
                        Produce the finest software possible through collaboration and pragmatism.
                    </p>
                    <p style={{ paddingTop: '30px' }}>
                        <h4>
                            Vision
                        </h4>
                        Technology should enable organizations to focus on their products and services. 
                        Software will be painless to develop and easy to use.
                    </p>
                    <p style={{ paddingTop: '30px' }}>
                        <h4>
                            Values
                        </h4>
                        <ul>
                            <li>Integrity: Do the right thing.</li>
                            <li>Accountability: Own it.</li>
                            <li>Service Oriented: The customer is the priority.</li>
                            <li>Attention To Detail: The difference between good and great.</li>
                            <li>Secure By Design: Security at every phase.</li>
                            <li>Whatever It Takes: Get it done.</li>
                        </ul>
                    </p>
                    <p style={{ paddingTop: '30px' }}>
                        Realistic Development is a software development and development operations (DevOps) consulting firm based in New Hampshire, USA. 
                        We solve business problems with technology and fearless pragmatism.
                    </p>
                    <p>
                        We build software for mobile and desktop apps, websites, automation and analytics. Security by design is essential; it is 
                        the consideration of security at every phase of the software development life cycle. It is treated as a core business requirement.  
                    </p>
                    <p>
                        DevOps is a methodology that incorporates technical tooling and processes as well as cultural philosophy. 
                        Effective DevOps requires a wholistic approach to infrastructure and software. Architecture, automation, monitoring, 
                        logging and security are all integral. The goal is to leverage continuous integration and continuous deployment 
                        to facilitate the seamless delivery of value from development teams while maintaining robust applications and infrastructure. 
                        Teams are no longer siloed and responsibilities are shared.
                    </p>
                </div>
			</div>
		</HelmetProvider>
	);
}

export default About;