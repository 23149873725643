import { useRef, useEffect } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Hit from '../Hit/Hit'

const Testimonials = () => {

    const hitLogged = useRef(false);
	useEffect(()=>{ 
		if (hitLogged.current) return;
    	hitLogged.current = true;
		Hit(5) 
	},[])

	return (
		<HelmetProvider>
			<Helmet>
				<title>{ process.env.REACT_APP_APP_NAME } - Testimonials</title>
			</Helmet>
			<div className='d-flex align-items-center justify-content-center paragraph-body' style={{paddingBottom: '80px'}}>
                <div className="col-lg-6 content-wrap">
                    <div className="center">
                        <h2>
                            Testimonials
                        </h2>
                    </div>
                    <p style={{ paddingTop: '30px' }}>
                        <h4>
                            Jacob N., Founder
                        </h4>
                        Dennis is a highly capable solutions architect and product developer. In our work with him, he has been able to 
                        develop software requirements, build effective solutions, and manage development and DevOps projects. I look forward 
                        to working with Dennis again down the road.
                    </p>
                    <p style={{ paddingTop: '30px' }}>
                        <h4>
                            Sonja G., Chief Information Officer
                        </h4>
                        I've had the pleasure of working with Dennis in a couple of engagements that have been successful. 
                        He has a broad spectrum of knowledge in IT gained from years of experience in both infrastructure and development. 
                        He is able to assess complex issues, discuss solutions, create a plan for resolution, work independently or with 
                        a team to complete the project, and finalize with documentation that translates into a production support tool. 
                        He is easy to work with and can adapt to working with people at various levels of technology skills.
                    </p>
                    <p style={{ paddingTop: '30px' }}>
                        <h4>
                            Kathi D., Director of Human Resources
                        </h4>
                        Our problem to solve was manual, time consuming, and frustrating. Annual MVR & DOT review. Our vendor said they have 
                        the ability to streamline this process and after more than eight months they were unable to provide a viable solution. 
                        Enter Dennis and his magic. In two months he took data from multiply sources and built a solution to evaluate all of 
                        our MVR's within 15 minutes! Now our annual review process from start to finish is down to 1 week instead of 4+ months. 
                        It is amazing to me....and this is not even scratching the surface of his talent and abilities. He was easy to work with, 
                        was thoughtful about potential pitfalls and proactive in resolving them before they became a pitfall. I highly recommend 
                        him to help you streamline your systems and processes. What is your barrier....he can remove it.
                    </p>
                </div>
			</div>
		</HelmetProvider>
	);
}

export default Testimonials;