import { useRef, useEffect } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Logo from '../Images/logo.png';
import Hit from '../Hit/Hit'

const Home = () => {
	
	const hitLogged = useRef(false);
	useEffect(()=>{ 
		if (hitLogged.current) return;
    	hitLogged.current = true;
		Hit(1) 
	},[])

	return (
		<HelmetProvider>
			<Helmet>
				<title>{ process.env.REACT_APP_APP_NAME } - Home</title>
			</Helmet>
			<div className='d-flex align-items-center justify-content-center' style={{ paddingTop: '10vh' }}>
				<img src={Logo} alt='Realistic Development - Logo' style={{ width: '60vh', maxWidth: '90vw' }} />
			</div>
			<div className='d-flex align-items-center justify-content-center' style={{ paddingTop: '7vh', letterSpacing: '3px' }}>
				<h2 style={{ fontSize: '35px' }}>SOFTWARE & DEVOPS</h2>
			</div>
			<div className='d-flex align-items-center justify-content-center center paragraph-home'>
				Integrity and Attention to Detail
			</div>
			<div className='d-flex align-items-center justify-content-center center paragraph-home' style={{paddingBottom: '80px'}}>
				<a href="/contact" className='blended-a'>Contact us today to start your project!</a>
			</div>
		</HelmetProvider>
	);
}

export default Home;