import { Outlet } from "react-router-dom";
import NavigationBar from "../NavigationBar";
import Footer from "../Footer";

const Layout = () => {
    return <div>
        <NavigationBar />
        <Outlet />
        <Footer />
    </div>;
};

export default Layout;
