import { useEffect, useState } from 'react'; 
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Layout from './Layout';
import Home from './Pages';
import About from './Pages/About';
import Articles from './Pages/Articles';
import Contact from './Pages/Contact';
import Testimonials from './Pages/Testimonials';
import Spinner from 'react-bootstrap/Spinner';

function App() {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);
  
  if (loading)
  {
    return (
      <div className="d-flex align-items-center justify-content-center p-3">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }
  else
  {
    return (
      <Router>
        <Routes>
            <Route path='/' element={
                <Layout />
            }>
                <Route index element={<Home />} />
                <Route path='/about' element={<About />} />
                <Route path='/articles' element={<Articles />} />
                <Route path='/contact' element={<Contact />} />
                <Route path='/testimonials' element={<Testimonials />} />
            </Route>
        </Routes>
      </Router>
    );
  }
}

export default App;