import { useState } from 'react'; 
import { Nav, Navbar, NavLink } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./NavStyle.css"

const NavigationBar = () => {
	const [expanded, setExpanded] = useState(false);

	return (
		<Navbar collapseOnSelect expand="sm" variant="dark" className="navigation" expanded={expanded}>
			<Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")} />
			<Navbar.Collapse>
				<Nav>
					<Navbar.Brand href="/" style={{marginLeft: '20px'}} onClick={() => setExpanded(false)}>REALISTIC DEVELOPMENT</Navbar.Brand>
					<NavLink as={Link} to="/about" onClick={() => setExpanded(false)}>ABOUT</NavLink>
					<NavLink as={Link} to="/articles" onClick={() => setExpanded(false)}>ARTICLES</NavLink>
					<NavLink as={Link} to="/contact" onClick={() => setExpanded(false)}>CONTACT</NavLink>
					<NavLink as={Link} to="/testimonials" onClick={() => setExpanded(false)}>TESTIMONIALS</NavLink>
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
};

export default NavigationBar;